<template>
  <section class="section">
    <quest-form></quest-form>
  </section>
</template>

<script>
import QuestForm from '@/components/Quests/QuestForm.vue';

export default {
  name: 'QuestCreate',
  components: {
    QuestForm,
  },
};
</script>

<style lang="scss">

</style>
