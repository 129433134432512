export const giants = [
  { id: 'alph', name: 'Alph' },
  { id: 'cosma', name: 'Cosma' },
  { id: 'friendly', name: 'Friendly' },
  { id: 'grendaline', name: 'Grendaline' },
  { id: 'humbaba', name: 'Humbaba' },
  { id: 'lem', name: 'Lem' },
  { id: 'mab', name: 'Mab' },
  { id: 'pot', name: 'Pot' },
  { id: 'spriggan', name: 'Spriggan' },
  { id: 'ti', name: 'Tii' },
  { id: 'zille', name: 'Zille' },
];

export const requirementsTypes = [
  'counter',
  'make',
  'buy_furniture',
  'buy_imagination',
  'buy_skill',
  'nodes_mined',
  'string',
];

export const prerequisitesTypes = [
  'quest',
  'skill',
];
