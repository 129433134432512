<template>
  <div>
    <h4 class="title has-text-centered is-4">{{ quest ? 'Quest Details' : 'New Quest' }}</h4>

    <form @submit="handleSubmit">
      <div class="box">
        <h5 class="title is-5">General</h5>
        <div class="columns">
          <div class="column is-3">
            <b-field label="ID" custom-class="is-small">
              <b-input v-model="formData.id" size="is-small"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Title" custom-class="is-small">
              <b-input v-model="formData.title" size="is-small"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Story" custom-class="is-small">
              <b-select v-model="formData.quest_story_id" size="is-small">
                <option></option>
                <option v-for="story in stories" :key="story.id" :value="story.id">{{ story.title }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Story Order" custom-class="is-small">
              <b-input v-model="formData.story_order" size="is-small"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column is-6">
            <b-field label="Description" custom-class="is-small">
              <b-input v-model="formData.description" type="textarea" size="is-small"></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Completion text" custom-class="is-small">
              <b-input v-model="formData.completion" type="textarea" size="is-small"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <b-field>
              <b-checkbox v-model="formData.repeatable" :true-value="1" :false-value="0" type="is-warning">Repeatable</b-checkbox>
            </b-field>
          </div>
          <div class="column is-6 has-text-right">
            <b-field>
              <b-checkbox v-model="formData.published" :true-value="1" :false-value="0" type="is-success"><b>PUBLISHED</b></b-checkbox>
            </b-field>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-9">
          <div class="box">
            <h5 class="title is-5">Requirements / Goals</h5>

            <b-button type="is-info" icon-left="plus" size="is-small" @click="addRequirement()">Add requirement</b-button>

            <div v-for="requirement in formData.requirements" :key="requirement.id" class="mt-4">
              <b-field grouped>
                <b-field label="Type" custom-class="is-small">
                  <b-select v-model="requirement.type" size="is-small">
                    <option v-for="type in requirementsTypes" :key="type" :value="type">{{ type }}</option>
                  </b-select>
                </b-field>
                <b-field label="Name" custom-class="is-small" expanded>
                  <b-input v-model="requirement.name" size="is-small"></b-input>
                </b-field>
                <b-field label="Description" custom-class="is-small" expanded>
                  <b-input v-model="requirement.description" size="is-small"></b-input>
                </b-field>
                <b-field label="Num" custom-class="is-small" expanded>
                  <b-input v-model="requirement.num" size="is-small"></b-input>
                </b-field>
                <b-field label="Class ID" custom-class="is-small" expanded>
                  <b-input v-model="requirement.class_id" size="is-small"></b-input>
                </b-field>
              </b-field>
            </div>
          </div>

          <div class="box">
            <h5 class="title is-5">Prerequisites</h5>

            <b-button type="is-info" icon-left="plus" size="is-small" @click="addPrerequisite()">Add prerequisite</b-button>

            <div v-for="prerequisite in formData.prerequisites" :key="prerequisite.id" class="mt-4">
              <b-field grouped>
                <b-field label="Positive" custom-class="is-small">
                  <b-select v-model="prerequisite.positive" size="is-small">
                    <option value="1">Must Have</option>
                    <option value="0">Must NOT Have</option>
                  </b-select>
                </b-field>
                <b-field label="Type" custom-class="is-small">
                  <b-select v-model="prerequisite.type" size="is-small">
                    <option v-for="type in prerequisitesTypes" :key="type" :value="type">{{ type }}</option>
                  </b-select>
                </b-field>
                <b-field label="Value" custom-class="is-small">
                  <b-input v-model="prerequisite.value" size="is-small"></b-input>
                </b-field>
              </b-field>
            </div>
          </div>

          <div class="box">
            <h5 class="title is-5">Dialogs</h5>

            <b-button type="is-info" icon-left="plus" size="is-small" @click="addDialog()">Add dialog</b-button>

            <div v-for="dialog in formData.dialogs" :key="dialog.id" class="mt-4">
              <b-field grouped>
                <b-field label="Rock Text" custom-class="is-small" expanded>
                  <b-input v-model="dialog.text" type="textarea" size="is-small"></b-input>
                </b-field>
                <b-field label="Player Reply" custom-class="is-small" expanded>
                  <b-input v-model="dialog.player_reply" type="textarea" size="is-small"></b-input>
                </b-field>
                <b-field label="Order" custom-class="is-small">
                  <b-input v-model="dialog.order" size="is-small"></b-input>
                </b-field>
              </b-field>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="box">
            <h5 class="title is-5">Rewards</h5>

            <b-field horizontal label="Giant Favor" custom-class="is-small">
              <b-select v-model="formData.reward_giant" size="is-small">
                <option></option>
                <option v-for="giant in giants" :key="giant.id" :value="giant.id">{{ giant.name }}</option>
              </b-select>
            </b-field>

            <b-field horizontal label="Favor" custom-class="is-small">
              <b-input v-model="formData.reward_giant_favor" size="is-small"></b-input>
            </b-field>

            <b-field horizontal label="XP" custom-class="is-small">
              <b-input v-model="formData.reward_xp" size="is-small"></b-input>
            </b-field>

            <b-field horizontal label="Currants" custom-class="is-small">
              <b-input v-model="formData.reward_currants" size="is-small"></b-input>
            </b-field>

            <b-field horizontal label="Mood" custom-class="is-small">
              <b-input v-model="formData.reward_mood" size="is-small"></b-input>
            </b-field>

            <b-field horizontal label="Energy" custom-class="is-small">
              <b-input v-model="formData.reward_energy" size="is-small"></b-input>
            </b-field>
          </div>
        </div>
      </div>

      <b-button type="is-success" native-type="submit">{{ quest ? 'Update' : 'Create' }}</b-button>

    </form>
  </div>
</template>

<script>
import { Quest } from '@/services/api';
import { giants, requirementsTypes, prerequisitesTypes } from '../../config/quests';

export default {
  name: 'quest-form',
  props: {
    quest: Object,
  },
  watch: {
    quest: {
      immediate: true,
      handler(val) {
        if (val) {
          this.formData = {
            ...val,
          };
        }
      },
    },
  },
  data() {
    return {
      errors: [],
      formData: {
        id: null,
        title: null,
        description: null,
        completion: null,
        dialogs: [],
        prerequisites: [],
        requirements: [],
        published: 0,
        quest_story_id: null,
        repeatable: 0,
        reward_giant: null,
        reward_giant_favor: null,
        reward_xp: null,
        reward_currants: null,
        reward_mood: null,
        reward_energy: null,
      },
      loadingForm: false,
      sent: false,
      stories: [],
      giants,
      requirementsTypes,
      prerequisitesTypes,
    };
  },
  mounted() {
    Quest.getStories()
      .then((data) => {
        this.stories = data;
      });
  },
  methods: {
    addRequirement() {
      this.formData.requirements.push({
        id: null,
        class_id: null,
        description: null,
        name: null,
        num: null,
        type: null,
      });
    },
    addPrerequisite() {
      this.formData.prerequisites.push({
        id: null,
        type: null,
        value: null,
        positive: 1,
      });
    },
    addDialog() {
      this.formData.dialogs.push({
        id: null,
        text: null,
        player_reply: null,
        order: this.getNextDialogOrder(),
      });
    },
    getNextDialogOrder() {
      const lastDialog = this.formData.dialogs.reduce((max, dialog) => (max.order > dialog.order ? max : dialog), { order: 0 });

      return lastDialog ? lastDialog.order + 1 : 1;
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const action = this.quest ? Quest.updateQuest(this.quest.id, this.formData) : Quest.createQuest(this.formData);

      action
        .then(() => {
          this.sent = true;
          if (this.quest) {
            this.refreshQuest();
          } else {
            this.refreshQuests();
          }
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    refreshQuest() {
      this.$store.dispatch('quest/loadQuestDetails', this.quest.id);
    },
    refreshQuests() {
      this.$store.dispatch('quest/loadQuests');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
